html * {
  box-sizing: border-box;
}

@font-face {
  font-family: bauhs;
  src: url('./assets/fonts/Bauhs-93.ttf');
}

@font-face {
  font-family: mtt-milano;
  src: url('./assets/fonts/Type\ Firm\ -\ MTT\ Milano.otf');
}

@font-face {
  font-family: mtt-milano-bold;
  src: url('./assets/fonts/Type\ Firm\ -\ MTT\ Milano\ Bold.otf');
}

body {
  margin: 0 auto;
  background-color:#D4F1F4;
  line-height: 0.9;
  font-family: mtt-milano;
  color: #0087BD;
  font-size: large;
}

h1, h2, h3, h4 {
  font-family: mtt-milano-bold;
  font-weight: 700;
  color: #0A1B5E;
}
